import * as React from 'react'

function SvgManageUser(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={18} viewBox="0 0 21 18" fill="none" {...props}>
      <path
        d="M8.734 8.864a4 4 0 100-8 4 4 0 000 8zM9.404 9.884c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53v2.65h9.26a6.963 6.963 0 01-.59-6.98zm10.08 2.98c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63l-.3-1.49h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63 0 .22.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63l.3 1.49h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zm-3.75 2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgManageUser
