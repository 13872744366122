import * as React from 'react'

function SvgPenIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M9.068 3.288l3.993 3.993-8.67 8.67-3.56.393a.749.749 0 01-.826-.827L.4 11.955l8.667-8.667zm6.463-.595L13.655.82a1.498 1.498 0 00-2.118 0L9.774 2.582l3.993 3.993 1.764-1.763a1.498 1.498 0 000-2.119z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgPenIcon
