import * as React from 'react'

function SvgUsersIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={37} height={30} viewBox="0 0 37 30" fill="none" {...props}>
      <path
        d="M5.548 12.946a3.702 3.702 0 003.699-3.7 3.702 3.702 0 00-3.699-3.698A3.702 3.702 0 001.85 9.247a3.702 3.702 0 003.7 3.699zm25.892 0a3.702 3.702 0 003.698-3.7 3.702 3.702 0 00-3.698-3.698 3.702 3.702 0 00-3.7 3.699 3.702 3.702 0 003.7 3.699zm1.849 1.85H29.59c-1.017 0-1.936.41-2.606 1.074a8.454 8.454 0 014.34 6.323h3.814c1.023 0 1.85-.827 1.85-1.85v-1.85a3.702 3.702 0 00-3.7-3.698zm-14.795 0a6.47 6.47 0 006.473-6.474 6.47 6.47 0 00-6.473-6.473 6.47 6.47 0 00-6.473 6.473 6.47 6.47 0 006.473 6.473zm4.438 1.849h-.48a9.104 9.104 0 01-3.958.924c-1.422 0-2.751-.347-3.959-.924h-.48a6.66 6.66 0 00-6.657 6.657v1.665a2.775 2.775 0 002.774 2.774h16.644a2.775 2.775 0 002.774-2.774v-1.665a6.66 6.66 0 00-6.658-6.657zm-12.928-.775a3.688 3.688 0 00-2.606-1.075h-3.7A3.702 3.702 0 000 18.494v1.85c0 1.022.826 1.849 1.85 1.849h3.808a8.475 8.475 0 014.346-6.323z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgUsersIcon
