import React from 'react'
import CallToActionSection from '../../components/call_to_action_section'
import FeatureRow from '../../components/features/feature_row'
import FourColsWithFeatures from '../../components/four_cols_features'
import Hero from '../../components/hero'
import { UserAccountHeroBackground } from '../../components/hero/hero_background'
// import { HeroIllustrationDevice } from '../../components/hero/hero_illustration'
import LargeImage from '../../components/large_image'
import Layout from '../../components/layout'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2 from '../../components/section_h2'
import SvgManageUser from '../../components/svgs/icons/manage_user_icon'
import SvgPenIcon from '../../components/svgs/icons/pen_icon'
import SvgUsersManagementIcon from '../../components/svgs/icons/products/users_management_icon'
import SvgUsersIcon from '../../components/svgs/icons/users_icon'
import SvgWebhookIcon from '../../components/svgs/icons/webhook_icon'

const featureIcons = [
  {
    feature01: <SvgWebhookIcon color="#54D2C4" />,
  },
  {
    feature02: <SvgManageUser color="#54D2C4" />,
  },
  {
    feature03: <SvgUsersIcon color="#54D2C4" />,
  },
  {
    feature04: <SvgPenIcon color="#54D2C4" />,
  },
]

const UsersManagementPage = ({ pageContext: { data, localePath } }: any) => {
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <Hero
        background_shape={<UserAccountHeroBackground />}
        icon={<SvgUsersManagementIcon />}
        category={data.category}
        call_to_action_color={data.slug}
        pageID={data.slug}
        title={data.header_title.raw}
        description={data.header_description.raw}
        value_propositions={data.value_proposition}
        call_to_action={data.call_to_action}
      >
        {/* <HeroIllustrationDevice heroData={data} /> */}
      </Hero>
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[0].section_H2_primary.title}
          description={data.body[0].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={null}
          gridColumns={true}
        >
          {/* <LargeImage
            imageType={'desktop-image'}
            shadow={true}
            url={data.body[1].primary.image.url}
            alt={data.body[1].primary.image.alt}
          /> */}
          <FeatureRow features={data.body[2].items} />
        </SectionH2>
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[3].section_H2_primary.title}
          description={data.body[3].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={null}
          color="on-dark-background"
          gridColumns={true}
        >
          <FourColsWithFeatures
            featureIcons={featureIcons}
            feature={data.body[4].items}
            slug={data.slug}
          />
        </SectionH2>
      </SectionWrapper>
      <CallToActionSection callToActionSectionData={data.body[5]} />
    </Layout>
  )
}

export default UsersManagementPage
